ion-header {
  ion-toolbar {
    // set background color
    --background: var(--ion-color-light);
    // set icon/text color
    --color: var(--ion-color-dark);
  }
}

ion-footer {
  ion-toolbar {
    // set background color
    --background: var(--ion-color-light);
    // set icon/text color
    --color: var(--ion-color-dark);
  }
}

ion-content {
  padding: 5px;
}

.Tile {
  background-color: green;
  width: 100%;
  height: 100%;
}

ion-grid {
  display: flex;
  flex-flow: column;
  max-width: 800px;
}

.tile-row {
  max-width: 800px;
  min-width: 330px;
}

.page-container {
  // Set backgroud colour to transparent so that background can overlap with pattern
  --ion-background-color: rgba(255, 255, 255, 0);

  ion-header {
    ion-toolbar {
      // set background color
      --background: transparent;
      // set icon/text color
      --color: var(--ion-color-dark);
    }
  }
}

.background {
  background-color: var(--ion-color-dark);
  background-image: repeating-linear-gradient(
      45deg,
      var(--theme-primary-color) 25%,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0) 75%,
      var(--theme-primary-color) 75%,
      var(--theme-primary-color)
    ),
    repeating-linear-gradient(
      45deg,
      #0d20aa 25%,
      #ffffff 25%,
      #ffffff 75%,
      #0d20aa 75%,
      #0d20aa
    );
  background-position: 0 0, 40px 40px;
  background-size: 80px 80px;
}

.tile-grid {
  max-width: 500px;
}

.grid-holder {
  min-width: none;
  border: var(--ion-toolbar-background) 1px solid;
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
