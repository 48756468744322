.container {
  width: 500px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light);
  color: var(--ion-color-dark);
  font-family: Arial, Helvetica, sans-serif;
}

.title-row {
  font-size: 16pt;
  overflow: hidden;
}

.technologies {
  font-size: 10pt;
  padding: 0;
  color: var(--ion-color-medium);
  p {
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

p {
  padding: 0;
  margin: 0;
}

#icon {
  width: 80px;
  height: 80px;
  mask-image: radial-gradient(circle, black 60%, rgba(0, 0, 0, 0) 60%);
}

#repo-link {
  --size: 20px;
}

a {
  font-size: 10pt;
  color: #ff9626 !important;
}

#thumbnail-col {
  max-width: 80px;
}

#info-column {
  padding: 10px 0px 10px 15px;

  align-items: center;
}

.main-column {
  padding: 0;
}
